import Image from "next/image";
import styles from './error.module.css';
import {NavLink} from '../ui';
import { useGlobalContext } from '../../lib/context'

export function Custom404(){
    const { props:{countryCode} } = useGlobalContext();
    const baseURL= `${process.env.SITE_URL}${countryCode == "ae" ? "/"+countryCode :""}`;
    
    return (
        <div className="container">
            <div className={styles.error404Sec}>
                <h1 className={styles.mainHeading}>ERROR 404</h1>
               <div className={styles.imgBx}>
                <div className={styles.subHeading}>page not found</div>
               <Image
                   className="img-responsive mb46"
                   width={415}
                   height={68}
                   src="/assets/images/Error404_2.svg"
                   alt=""/>
                <Image
                    className="img-responsive mb46"
                    width={498}
                    height={202}
                    src="/assets/images/Error404_1.svg"
                    alt=""
                     />
               </div>
                <NavLink href={baseURL} exact className='btn btnPrimery mt25'>BACK TO HOME PAGE</NavLink>
            </div>
        </div>
    );
}