import type { NextPage } from 'next';
import { Custom404 } from '@/components/errorPages/custom404';
import { useEffect } from 'react';
import { useGlobalContext } from '@/lib/context/GlobalContext';
const Page404: NextPage = () => {
  const { funs: { toggleFloatingWidget } } = useGlobalContext();
  useEffect(() => {
    toggleFloatingWidget(true);
  }, []);
  return (
    <Custom404 />
  )
}

export default Page404